<template>
    <div class="admin-time-dispute-filter">
        <Card dis-hover>
            <Row type="flex" justify="space-between" class="code-row-bg title-wrapper">
                <Col span="6">
                    <Card class="page-title" dis-hover :bordered="false"> Employee Time Disputes </Card>
                </Col>
            </Row>
            <Row type="flex">
                <Col :xs="2" :sm="4" :md="4" :lg="4" class="d-flex-center user-col">
                    <Select
                        v-model="selectedEmployee"
                        :value="$route.query.user"
                        filterable
                        clearable
                        placeholder="All Employees"
                        @on-change="handleUserChanged"
                    >
                        <Option v-for="item in filterEmployeeList" :key="item.key" :value="item.value">
                            {{ item.name }}
                        </Option>
                    </Select>
                </Col>
                <Col :xs="2" :sm="4" :md="4" :lg="4" class="d-flex-center status-col">
                    <Select
                        v-model="selectedStatus"
                        :value="$route.query.status"
                        filterable
                        clearable
                        placeholder="Filter by"
                        @on-change="handleStatusChanged"
                    >
                        <Option v-for="item in requestStatus" :key="item.value" :value="item.value">
                            {{ item.name }}
                        </Option>
                    </Select>
                </Col>
              <Col :xs="2" :sm="4" :md="4" :lg="4" class="d-flex-center status-col">
                <DatePicker v-model="timeDisputeDateFilter" placeholder="Filter by date" type="daterange" format="MM-dd-yyyy"  @on-change="onDateChange"></DatePicker>
              </Col>
            </Row>
        </Card>
    </div>
</template>

<script>
import Bugsnag from '@bugsnag/js';
import moment from "moment";
// import moment from 'moment';
export default {
    props: {
        allEmployees: {
            type: Array,
            default: () => [],
        },
        selectedDates: {
          default: null
        }
    },

    data() {
        return {
            selectedStatus: this.$route.query.status,
            selectedEmployee: this.$route.query.user,
            filterEmployeeList: [],
            timeDisputeDateFilter: null
        };
    },
    watch: {
        allEmployees(data) {
            console.log('filter-employees: ', data);
            this.filterEmployeeList = data;
        },
    },

    created() {
        if (this.$route.query.status) {
            this.$emit('status-changed', this.selectedStatus);
        }
        if (this.$route.query.user) {
            this.$emit('user-changed', this.selectedEmployee);
        }
        if (this.allEmployees) {
            this.filterEmployeeList = this.allEmployees;
        }
        this.timeDisputeDateFilter = [
          moment(this.selectedDates.from).format('MM-DD-YYYY'),
          moment(this.selectedDates.to).format("MM-DD-YYYY")]
        this.onDateChange(this.timeDisputeDateFilter)
    },

    methods: {
        handleUserChanged(user) {
            this.$emit('user-changed', user);
            try {
                this.$router.replace({ query: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        handleStatusChanged(stats) {
            this.$emit('status-changed', stats);
            try {
                this.$router.replace({ query: null }).catch(() => {});
            } catch (e) {
                Bugsnag.notify(e);
            }
        },
        onDateChange(date){
          this.$emit('date-change', {from: date[0], to: date[1]})
        }
    },
};
</script>
<style lang="scss">
.admin-time-dispute-filter {
    .ivu-row {
        .export-btn {
            justify-content: end;
            button {
                border-radius: 15px;
            }
        }
    }
    .user-col {
        margin-right: 10px;
    }
}
@media screen and (max-width: 767px) {
    .admin-time-dispute-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
