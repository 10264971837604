<template>
    <div class="user-dashboard-timetracker">
        <Greetings v-if="userRole !== 'admin'" />
        <Card v-if="userRole !== 'admin'" dis-hover class="md-margin-bottom att-display-inline">
            <p style="color: #463c6e; font-size: 18px; font-weight: 600; margin-bottom: 15px">Attendance</p>
            <div class="d-flex-center space-between att-project-selector">
                <p
                    v-if="
                        attendanceLogData &&
                        attendanceLogData.clockInTime != null &&
                        attendanceLogData.clockOutTime == null
                    "
                    class="lg-margin-right"
                >
                    Last clock in: {{ lastClockIn }}
                </p>
                <p v-else class="lg-margin-right">Last clock out: {{ lastClockOut }}</p>
              <Button
                  v-if="isEmployeeOnLeave"
                  class="pt-btn-1"
                  :loading="isLoading"
                  disabled
                  @click="VerifyClockOut">
                On Leave
              </Button>
                <Button
                    v-else-if="
                        attendanceLogData &&
                        attendanceLogData.clockInTime != null &&
                        attendanceLogData.clockOutTime == null
                    "
                    class="pt-btn-1"
                    :loading="isLoading"
                    :disabled="timeLogData && timeLogData.startTime != null && timeLogData.endTime == null"
                    @click="VerifyClockOut"
                >
                    Clock Out
                </Button>
                <Button v-else class="pt-btn-4" :loading="isLoading" @click="VerifyClockedIn"> Clock In </Button>
            </div>
            <ClockInReminderModal ref="ClockInReminderModal" @confirm="handleClockIn" />
            <ClockOutReminderModal ref="ClockOutReminderModal" @confirm="handleUpdateClockOut" />
        </Card>
        <Card :id="userRole !== 'admin' ? 'display-inline' : '#'" dis-hover class="md-margin-bottom">
            <p style="color: #463c6e; font-size: 18px; font-weight: 600; margin-bottom: 15px">Project Time Tracker</p>
            <div v-if="userRole === 'admin'" class="d-flex-center project-selector" style="margin-bottom: 10px">
                <p class="xs-margin-right">Project</p>
                <Select
                    v-model="selectedProject"
                    :disabled="timeLogData && timeLogData.startTime != null && timeLogData.endTime == null"
                    filterable
                    clearable
                    :loading="isLoading"
                    class="md-margin-right"
                >
                    <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </Option>
                </Select>
                <Button
                    v-if="timeLogData && timeLogData.startTime != null && timeLogData.endTime == null"
                    class="pt-btn-1"
                    :loading="isLoading"
                    @click="VerifySalariumCheckedOut"
                >
                    End Time
                </Button>
                <Button
                    v-else
                    class="pt-btn-4"
                    :loading="isLoading"
                    :disabled="selectedProject != null ? false : true"
                    @click="VerifySalariumCheckedIn"
                >
                    Start Time
                </Button>
            </div>
            <div v-else class="d-flex-center project-selector">
                <p class="xs-margin-right">Project</p>
                <Select
                    v-model="selectedProject"
                    :disabled="
                        !attendanceLogData ||
                        (attendanceLogData &&
                            timeLogData &&
                            timeLogData.startTime != null &&
                            timeLogData.endTime == null)
                    "
                    filterable
                    clearable
                    :loading="isLoading"
                    class="md-margin-right"
                >
                    <Option v-for="item in projectsList" :key="item.value" :value="item.value">
                        {{ item.label }}
                    </Option>
                </Select>
                <Button
                    v-if="timeLogData && timeLogData.startTime != null && timeLogData.endTime == null"
                    class="pt-btn-1"
                    :loading="isLoading"
                    @click="handleUpdateEndTime(true)"
                >
                    End Time
                </Button>
                <Button
                    v-else
                    class="pt-btn-4"
                    :loading="isLoading"
                    :disabled="selectedProject != null ? false : true"
                    @click="handlePostStartTime(true)"
                >
                    Start Time
                </Button>
            </div>
        </Card>
        <Card v-if="userRole != 'admin'" dis-hover class="half-width">
            <TrackerBarGraph
                id="trackerBarGraphEmail"
                style="height: 300px; position: relative"
                :chartdata-sets="chartData"
                :chartdata-labels="chartDataLabels"
                :max-ticks="chartDataMaxTicks"
            />
        </Card>
        <SalariumReminderModal ref="SalariumReminderModal" @confirm="handlePostStartTime" />
        <StopSalariumReminderModal ref="StopSalariumReminderModal" @confirm="handleUpdateEndTime" />
    </div>
</template>

<script>
import { getProjectByProjectMembers } from '@/services/project-members.services';
import { postTimelogsStart, updateEndTime, getBarTimelogsByUserv2 } from '@/services/timelogs.services';
import { getTimeTrackingByUser } from '@/services/timelogs.services';
import { getAttendanceByUser, postClockIn, updateClockOut } from '@/services/attendancelogs.services';
import TrackerBarGraph from '@/components/Graphs/BarChart.js';
import SalariumReminderModal from './SalariumReminderModal.vue';
import StopSalariumReminderModal from './StopSalariumReminderModal.vue';
import Greetings from './Greetings.vue';
import moment from 'moment';
import Bugsnag from '@bugsnag/js';
import { checkIfBeforeEightAM } from '../../../../utility/helper';
import { getUsersById } from '@/services/users.services';
import ClockInReminderModal from '@/components/Attendance/Dev/ClockInReminderModal';
import ClockOutReminderModal from '@/components/Attendance/Dev/ClockOutReminderModal';

export default {
    components: {
        TrackerBarGraph,
        SalariumReminderModal,
        StopSalariumReminderModal,
        Greetings,
        ClockInReminderModal,
        ClockOutReminderModal,
    },

    data() {
        return {
            timeLogData: null,
            isLoading: false,
            selectedProject: null,
            projectsList: [],
            timelogList: [],
            user_id: localStorage.getItem('user_id'),
            userRole: localStorage.getItem('mainRole'),
            chartData: [],
            chartDataLabels: [],
            daily8Data: [],
            timelogsData: [],
            chartDataMaxTicks: [],
            attendanceLogData: null,
            lastClockIn: null,
            lastClockOut: null,
            isEmployeeOnLeave: false
        };
    },

    mounted() {
        this.handleGetTimelogUser();
        this.handleGetBarTimelogUser();
        this.handleGetProjects();
        this.handleGetAttendanceLogUser();
        this.handleGetUserDetails();
    },

    methods: {
        /*Project Time Logs Section*/
        handleGetProjects() {
            this.isLoading = true;
            this.projectsList = [];
            getProjectByProjectMembers(this.user_id)
                .then((res) => {
                    if (res.data.length) {
                        this.projectsList = res.data
                            .filter(
                                (x) =>
                                    x.project.isActive === true &&
                                    x.project.isArchived === false &&
                                    x.isProjectMemberActive === true
                            )
                            .map((i) => {
                                return {
                                    label: i.project.name,
                                    value: i.project._id,
                                };
                            });
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.isLoading = false;
                });
        },

        VerifySalariumCheckedIn() {
            this.$refs.SalariumReminderModal.openModal();
        },

        VerifySalariumCheckedOut() {
            this.$refs.StopSalariumReminderModal.openModal();
        },

        handlePostStartTime(checked) {
            if (checked) {
                if (this.selectedProject != null) {
                    this.isLoading = true;
                    const data = {
                        project: this.selectedProject,
                        user: this.user_id,
                    };
                    postTimelogsStart(data)
                        .then((res) => {
                            this.timeLogData = res.data;
                            this.isLoading = false;
                        })
                        .catch(() => {
                            this.$Message.error('Failed to start time');
                            this.isLoading = false;
                        });
                } else {
                    this.$Message.error('Please select a project');
                }
            }
        },

        handleGetTimelogUser() {
            this.isLoading = true;
            getTimeTrackingByUser(this.user_id)
                .then((res) => {
                    if (checkIfBeforeEightAM) {
                        this.timeLogData = res.data.filter(
                            (x) => x.endTime == null && moment(x.startTime).isSame(moment(), 'day')
                        )[0];
                    } else {
                        this.timeLogData = res.data.filter(
                            (x) => x.endTime == null && moment(x.startTime).utc().isSame(moment().utc(), 'day')
                        )[0];
                    }
                    this.selectedProject = this.timeLogData.project._id;
                    this.isLoading = false;
                })
                .catch(() => {
                    // this.$Message.error('Failed to get timelog user')
                    this.isLoading = false;
                });
        },

        handleGetBarTimelogUser() {
            this.chartData = [];
            getBarTimelogsByUserv2(this.user_id).then((res) => {
                try {
                    if (res.data) {
                        this.chartDataLabels = res.data.dataLabel;
                        res.data.dataSets.map((i) => {
                            const dataObj = {
                                label: i.label,
                                backgroundColor: i.backgroundColor,
                                data: i.data,
                            };
                            this.chartDataMaxTicks = Math.max(...dataObj.data);
                            this.chartData.push(dataObj);
                        });
                    }
                } catch (e) {
                    Bugsnag.notify(e);
                }
            });
        },

        handleUpdateEndTime(checked) {
            if (checked) {
                this.isLoading = true;
                const data = {
                    project: this.selectedProject,
                    user: this.user_id,
                };
                updateEndTime(this.timeLogData._id, data)
                    .then(() => {
                        this.timeLogData = null;
                        this.isLoading = false;
                        this.handleGetBarTimelogUser();
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get end time');
                        this.isLoading = false;
                    });
            }
        },
        /*Attendance Logs Section*/
        handleGetUserDetails() {
            const userId = localStorage.getItem('user_id');
            getUsersById(userId)
                .then((res) => {
                    if (checkIfBeforeEightAM) {
                        if (res.data.monitoring_last_time_clock_in) {
                            this.lastClockIn = moment(res.data.monitoring_last_time_clock_in).format(
                                'MM/DD/yyyy, hh:mm a'
                            );
                        }
                        if (res.data.monitoring_last_time_clock_out) {
                            this.lastClockOut = moment(res.data.monitoring_last_time_clock_out).format(
                                'MM/DD/yyyy, hh:mm a'
                            );
                        }
                    } else {
                        if (res.data.monitoring_last_time_clock_in) {
                            this.lastClockIn = moment(res.data.monitoring_last_time_clock_in)
                                .utc()
                                .format('MM/DD/yyyy, hh:mm a');
                        }
                        if (res.data.monitoring_last_time_clock_out) {
                            this.lastClockOut = moment(res.data.monitoring_last_time_clock_out)
                                .utc()
                                .format('MM/DD/yyyy, hh:mm a');
                        }
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get users');
                });
        },
        VerifyClockedIn() {
            this.$refs.ClockInReminderModal.openModal();
        },
        VerifyClockOut() {
            this.$refs.ClockOutReminderModal.openModal();
        },
        handleGetAttendanceLogUser() {
            this.isLoading = true;
            getAttendanceByUser(this.user_id)
                .then((res) => {
                  console.log('res: ', res)
                    if (checkIfBeforeEightAM) {
                        this.attendanceLogData = res.data.filter(
                            (x) => x.clockOutTime == null && moment(x.clockInTime).isSame(moment(), 'day')
                        )[0];
                    } else {
                        this.attendanceLogData = res.data.filter(
                            (x) => x.clockOutTime == null && moment(x.clockInTime).utc().isSame(moment().utc(), 'day')
                        )[0];
                    }
                  this.isEmployeeOnLeave = res.data[0].status.includes('leave')
                    this.isLoading = false;
                })
                .catch(() => {
                    // this.$Message.error('Failed to get attendance logs user')
                    this.isLoading = false;
                });
        },

        handleClockIn() {
            if (this.user_id) {
                this.isLoading = true;
                const data = {
                    user: this.user_id,
                };
                postClockIn(data)
                    .then((res) => {
                        this.attendanceLogData = res.data;
                        this.isLoading = false;
                        this.handleGetUserDetails();
                    })
                    .catch((err) => {
                        if (err.data && err.status === 400) {
                            this.$Message.error(err.data?.message);
                        } else this.$Message.error('Failed to clock in');
                        this.isLoading = false;
                    });
            } else {
                this.$Message.error('Failed to found user');
            }
        },

        handleUpdateClockOut() {
            if (this.attendanceLogData._id) {
                this.isLoading = true;
                const data = {
                    user: this.user_id,
                };
                updateClockOut(this.attendanceLogData._id, data)
                    .then(() => {
                        this.attendanceLogData = null;
                        this.selectedProject = null;
                        this.isLoading = false;
                        this.handleGetUserDetails();
                    })
                    .catch(() => {
                        this.$Message.error('Failed to get clock out');
                        this.isLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.user-dashboard-timetracker {
    .ivu-card.ivu-card-bordered.ivu-card-dis-hover {
        box-shadow: 0 1px 6px rgb(0 0 0 / 20%);
        border-color: #eee;
        border-radius: 5px;
    }
    #display-inline {
        display: inline-block;
        margin-left: 18px;
        width: 45%;
    }
    .half-width {
        width: 50%;
    }
    @media screen and (max-width: 1024px) {
        .project-selector {
            display: block;
            text-align: left;

            .ivu-select {
                margin: 0 0 10px;
            }
            button {
                width: 100%;
            }
        }
    }
}
.att-display-inline {
    display: inline-block;
    width: 50%;
}
.lg-margin-right {
    margin-right: 60px;
}
.space-between {
    justify-content: space-between;
}
@media screen and (max-width: 1024px) {
    .att-project-selector {
        display: block;
        text-align: left;

        button {
            width: 100%;
        }
    }
}
</style>
