<template>
    <div class="asana-todo-list">
        <Tabs
            v-if="Object.keys(project).length && project.asana.length"
            name="asana_todo_list"
            class="proj-asana-tab-list"
            @on-click="handleOnAsanaChange"
        >
            <TabPane v-for="(asana, idx) in project.asana" :key="idx" :label="asana.name" :name="asana.id" tab="asana_todo_list">
                <div v-if="asanaProjectSections.length">
                    <!-- <AsanaTodos
                        v-for="(section, idx) in asanaProjectSections"
                        :key="idx"
                        class="md-margin-bottom"
                        :selected-assigned-todos="selectedAssignedTodos"
                        :section="section"
                        :selected-email-type="selectedEmailType"
                        :selected-project="selectedProject"
                        :project="project"
                        @handleAsanaSelections="handleAsanaSelections"
                    /> -->
                    <AsanaTodos
                        class="md-margin-bottom"
                        :section="asanaProjectSections"
                        :selected-assigned-todos="selectedAssignedTodos"
                        :selected-email-type="selectedEmailType"
                        :selected-project="selectedProject"
                        :project="project"
                        @handleAsanaSelections="handleAsanaSelections"
                    />
                </div>
                <p v-else class="text-center">No Data</p>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import { getAsanaProjectSections } from '@/services/asana.services';
import AsanaTodos from '@/components/WeeklyEmails/Asana/AsanaTodos';

export default {
    components: {
        AsanaTodos,
    },
    props: {
        project: {
            type: Object,
            default: () => {},
        },
        selectedAssignedTodos: {
            type: Array,
            default: () => [],
        },
        selectedEmailType: {
            type: String,
            default: 'plan',
        },
        selectedProject: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            asanaProjectSections: [],
            asanaSelections: {},
        };
    },
    watch: {
        project: {
            handler(data) {
                this.asanaProjectSections = [];
                if (data && Object.keys(data).length && data.asana.length) {
                    this.handleGetAsanaProjectSections(data.asana[0].id);
                }
            },
            deep: true,
        },
    },
    mounted() {
        this.asanaProjectSections = [];
        if (this.project && Object.keys(this.project).length && this.project.asana.length) {
            this.handleGetAsanaProjectSections(this.project.asana[0].id);
        }
    },

    methods: {
        handleGetAsanaProjectSections(id) {
            getAsanaProjectSections(id)
                .then((res) => {
                    if (res.data && res.data.data.length) {
                        this.asanaProjectSections = res.data.data.filter((x) => {
                            const secName = x.name.toLowerCase();
                            if (this.selectedEmailType == 'plan') {
                                if (secName.includes('todo'.toLowerCase()) || secName.includes('To Do'.toLowerCase())) {
                                    this.asanaSelections[x.gid] = [];
                                    return x;
                                }
                            } else {
                                if (secName.includes('Done'.toLowerCase())) {
                                    this.asanaSelections[x.gid] = [];
                                    return x;
                                }
                            }
                        });
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get asana project tasks');
                });
        },

        handleAsanaSelections(gid, data) {
            this.asanaSelections[gid] = data;
            this.$emit('handleAsanaSelections', this.asanaSelections);
        },

        handleOnAsanaChange(val) {
            this.handleGetAsanaProjectSections(val);
        },
    },
};
</script>

<style></style>
