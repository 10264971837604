<template>
    <div class="timetracker-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg title-wrapper" style="margin-bottom: 20px">
            <Col span="6">
                <Card class="page-title" dis-hover :bordered="false"> TIME TRACKER </Card>
            </Col>
            <Col span="6" class="text-right" style="padding: 16px">
                <div class="savePTBtn">
                    <Button class="pt-btn-1" @click="handleAddTimelog"> Add Time Record </Button>
                </div>
            </Col>
        </Row>
        <AddTimestamp ref="AddTimestamp" @add="handleAddList" />
        <TimeTrackerFilter
            :project-members-list="projectMembersList"
            :projects-list="projectsList"
            style="margin-bottom: 20px"
            @filter="handleOnFilter"
            @project-changed="handleOnProjectChanged"
        />
        <TimeTrackerList
            v-if="(selectedProject || selectedMember) && isFilterClick"
            :time-logs-list="timeLogsList"
            :is-loading="isLoading"
            :total-count="totalCount"
            :current-page="currentPage"
            :total-per-page="totalPerPage"
            @reload="handleReload"
            @on-page-change="handleOnPageChanged"
        />
    </div>
</template>

<script>
import { getProjectMembers, getTimelogProjectMembersByProjectId } from '@/services/project-members.services';
import { getAggregatedTimelogs } from '@/services/timelogs.services';
import { getProjects } from '@/services/projects.services';
import TimeTrackerFilter from '@/components/Timetracker/TimeTrackerFilter';
import TimeTrackerList from '@/components/Timetracker/TimeTrackerList';
import AddTimestamp from '@/components/Timetracker/AddTimestamp';

export default {
    components: {
        TimeTrackerFilter,
        TimeTrackerList,
        AddTimestamp,
    },
    data() {
        return {
            isLoading: false,
            projectMembersList: [],
            projectsList: [],
            timeLogsList: [],
            selectedProject: this.$route.query.user,
            selectedMember: this.$route.query.projectId,
            selectedDates: null,
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPerPage: {},
        };
    },
    created() {
        // this.handleGetTimelogs()
        this.handleGetProjectMembersList();
        this.handleGetProjectsList();
        if (this.$route.query.user) {
            this.handleOnProjectChanged(this.$route.query.projectId);
            this.handleOnFilter(this.$route.query.user, this.$route.query.projectId);
        }
    },
    // mounted() {
    //   this.handleGetTimelogs()
    //   // this.handleGetProjectMembersList()
    //   this.handleGetProjectsList()
    // },

    methods: {
        handleGetTimelogs() {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs({}, this.currentPage, 25)
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.totalCount = res.data.totalCount;
                        this.timeLogsList = res.data.data;
                        this.totalPerPage = res.data.total;
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs');
                    this.isLoading = false;
                });
        },
        handleGetProjectMembersByProjectId(projId) {
            getTimelogProjectMembersByProjectId(projId)
                .then((res) => {
                    if (res.data.length) {
                        // const temp = res.data.filter((x) => x.isProjectMemberActive === true);
                        this.parseMembersListData(res.data);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get project members by project');
                    this.projectMembersList = [];
                });
        },
        handleGetProjectMembersList() {
            this.projectMembersList = [];
            getProjectMembers()
                .then((res) => {
                    if (res.data.length) {
                        const temp = res.data
                            .filter((x) => x.isProjectMemberActive === true)
                            .map((projectMember) => projectMember.user);
                        this.parseMembersListData(temp);
                    } else {
                        this.projectMembersList = [];
                    }
                })
                .catch((error) => {
                    this.$Message.error('Failed to get project members list');
                    this.projectMembersList = [];
                    console.log(error)
                });
        },
        handleGetTimelogsByProjectId(projId, date = null) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs(
                { project: projId, ...(date != null && { startTime: { $gte: date.from, $lte: date.to } }) },
                this.currentPage,
                25
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.timeLogsList = res.data.data.filter((i) => i.elem.project._id == projId);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get project by id');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },
        handleGetProjectsList() {
            this.projectsList = [];
            getProjects()
                .then((res) => {
                    if (res.data.length) {
                        const filteredData = res.data.filter(function (e) {
                            return e.isArchived === false && e.isActive === true;
                        });
                        this.projectsList = filteredData.map((i) => {
                            return {
                                value: i._id,
                                label: i.name,
                            };
                        });
                        this.projectsList = this.projectsList.sort((a, b) => (a.label > b.label ? 1 : -1));
                    } else {
                        this.projectsList = [];
                    }
                })
                .catch(() => {
                    this.$Message.error('Failed to get projects');
                    this.projectsList = [];
                });
        },
        parseMembersListData(data) {
            // Remove duplicates
            let resArr = [];
            data.forEach((user) => {
                if (user) {
                    const i = resArr.findIndex((x) => x._id === user._id);
                    if (i <= -1) {
                        resArr.push(user);
                    }
                }
            });

            // Remove clients
            // Extract id and name data only
            // Sort by name/label
            this.projectMembersList = resArr
                .filter((user) => user.mainRole !== 'client')
                .map((user) => {
                    return {
                        value: user._id,
                        label: user.name,
                    };
                })
                .sort((a, b) => (a.label > b.label ? 1 : -1));
        },
        handleOnFilter(member, project, date) {
            this.selectedProject = project;
            this.selectedMember = member;
            this.selectedDates = date;
            this.isFilterClick = true;
            if (member && project) {
                this.currentPage = 1;
                this.handleGetTimelogsByProjectUser(project, member, date);
            } else if (member) {
                this.currentPage = 1;
                this.handleGetTimeLogsByUser(member, date);
            } else if (project) {
                this.currentPage = 1;
                this.handleGetTimelogsByProjectId(project, date);
            }
        },
        handleOnProjectChanged(projId) {
            this.selectedProject = projId;
            this.isFilterClick = false;
            if (projId) {
                this.handleGetProjectMembersByProjectId(projId);
            } else {
                this.handleGetProjectMembersList();
            }
        },
        handleReload() {
            if (this.selectedMember && this.selectedProject) {
                this.handleGetTimelogsByProjectUser(this.selectedProject, this.selectedMember);
            } else if (this.selectedMember) {
                this.handleGetTimeLogsByUser(this.selectedMember);
            } else if (this.selectedProject) {
                this.handleGetTimelogsByProjectId(this.selectedProject);
            }
        },
        handleGetTimelogsByProjectUser(projId, userId, date = null) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs(
                {
                    project: projId,
                    user: userId,
                    ...(date != null && { startTime: { $gte: date.from, $lte: date.to } }),
                },
                this.currentPage,
                25
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.timeLogsList = res.data.data.filter(
                            (i) => i.elem.project._id == projId && i.elem.user._id == userId
                        );
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by project and user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },
        handleGetTimeLogsByUser(userId, date = null) {
            this.isLoading = true;
            this.timeLogsList = [];
            getAggregatedTimelogs(
                { user: userId, ...(date != null && { startTime: { $gte: date.from, $lte: date.to } }) },
                this.currentPage,
                25
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.totalCount = res.data.totalCount;
                        this.totalPerPage = res.data.total;
                        this.timeLogsList = res.data.data.filter((i) => i.elem.user._id == userId);
                    } else {
                        this.timeLogsList = [];
                    }
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get timelogs by user');
                    this.timeLogsList = [];
                    this.isLoading = false;
                });
        },

        handleAddList() {
            this.handleReload();
        },

        handleAddTimelog() {
            this.$refs.AddTimestamp.openModal();
        },
        handleOnPageChanged(page) {
            if (this.selectedMember && this.selectedProject) {
                this.currentPage = page;
                this.handleGetTimelogsByProjectUser(this.selectedProject, this.selectedMember, this.selectedDates);
            } else if (this.selectedMember) {
                this.currentPage = page;
                this.handleGetTimeLogsByUser(this.selectedMember, this.selectedDates);
            } else if (this.selectedProject) {
                this.currentPage = page;
                this.handleGetTimelogsByProjectId(this.selectedProject, this.selectedDates);
            }
        },
    },
};
</script>
<style lang="scss">
.timetracker-wrapper {
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
