<template>
  <div>
    <div>
      <Table :columns="tblColumns" :data="tblData" size="small" :loading="isLoading" class="adminRequestsList" />
    </div>
    <Page
        :total="totalCount"
        :current="currentPage"
        :page-size="totalPerPage"
        class="pagination"
        @on-change="handleOnpageChanged"
    />
    <ViewRequestModal ref="ViewRequest" @create="handleReloadList" />
  </div>
</template>

<script>
import moment from 'moment';
import ViewRequestModal from '@/components/Requests/Dev/Leave/ViewRequestModal';
export default {
  components: { ViewRequestModal },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    requestsDataList: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    totalPages: {
      type: Number,
      default: 0,
    },
    totalPerPage: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      tblColumns: [
        {
          title: 'Date Created',
          key: 'createdAt',
          width: 100,
          render: (h, params) => {
            return h(
                'div',
                params.row.createdAt
                    ? moment(params.row.createdAt).utc().format('YYYY-MM-DD')
                    : ''
            );
          },
        },
        {
          title: 'Employee',
          key: 'user',
          width: 150,
          render: (h, params) => {
            return h('div', params.row.user.name);
          },
        },
        {
          title: 'Start Date',
          key: 'requestedStart',
          //fixed: 'left',
          width: 150,
          render: (h, params) => {
            return params.row.requestedStart
                ? h(
                    'div',
                    `${moment(params.row.requestedStart).utc().format('yyyy-MM-DD')}`
                )
                : h('div', { class: 'hyphenFont' }, '-');
          },
        },
        {
          title: 'End Date',
          key: 'requestedEnd',
          width: 150,
          render: (h, params) => {
            return params.row.requestedEnd
                ? h(
                    'div',
                    `${moment(params.row.requestedEnd).utc().format('yyyy-MM-DD')}`
                )
                : h('div', { class: 'hyphenFont' }, '-');
          },
        },
        {
          title: 'Leave Type',
          key: 'requestedData.type',
          width: 150,
          render: (h, params) => {
            console.log(' params.row.requestedData', params.row.requestedData.type)
            return h('div', params.row.requestedData.type);
          },
        },
        {
          title: 'Status',
          key: 'status',
          minWidth: 100,
          render: (h, params) => {
            // return h('div', params.row.status);
            const status = params.row.status;
            let label = '',
                className = 'statusLabel';
            const upperCaseStats = status.toString().toUpperCase();
            switch (status.toString().trim()) {
              case 'pending':
                label = upperCaseStats;
                className = `${className} warning`;
                break;
              case 'rejected':
                label = upperCaseStats;
                className = `${className} danger`;
                break;
              case 'approved':
                label = upperCaseStats;
                className = `${className} success`;
                break;
              case 'cancelled':
                label = upperCaseStats;
                className = `${className} warning`;
                break;
              default:
                break;
            }
            return h(
                'div',
                {
                  class: `${className}`,
                },
                label
            );
          },
        },
        {
          title: 'Actions',
          minWidth: 200,
          align: 'left',
          render: (h, params) => {
            return h('div', [
              h('Tooltip', { props: { placement: 'top', content: 'View' } }, [
                h(
                    'Button',
                    {
                      class: 'pt-btn-3',
                    },
                    [
                      h('Icon', {
                        props: {
                          type: 'md-eye',
                          size: '25',
                        },
                        on: {
                          click: () => {
                            console.log('params: ', params)
                            this.$refs.ViewRequest.openModal(params.row, false);
                            console.log(params.row);
                          },
                        },
                      }),
                    ],
                    'view'
                ),
              ]),
              h('Tooltip', { props: { placement: 'top', content: 'Approve' } }, [
                h(
                    'Button',
                    {
                      class: `${
                          params.row.status && params.row.status.includes('pending')
                              ? 'pt-btn-4'
                              : 'btn-hide'
                      }`,
                    },
                    [
                      h('Icon', {
                        props: {
                          type: 'md-checkmark',
                          size: '25',
                        },
                        on: {
                          click: () => {
                            this.handleProcessRequest(params.row._id, 'approved');
                            console.log(params.row);
                          },
                        },
                      }),
                    ],
                    'approve'
                ),
              ]),
              h('Tooltip', { props: { placement: 'top', content: 'Reject' } }, [
                h(
                    'Button',
                    {
                      class: `${
                          params.row.status && params.row.status.includes('pending')
                              ? 'pt-btn-1'
                              : 'btn-hide'
                      }`,
                    },
                    [
                      h('Icon', {
                        props: {
                          type: 'md-close',
                          size: '25',
                        },
                        on: {
                          click: () => {
                            this.handleProcessRequest(params.row._id, 'rejected');
                            console.log(params.row);
                          },
                        },
                      }),
                    ],
                    'reject'
                ),
              ]),
              h('Tooltip', { props: { placement: 'top', content: 'Cancel' } }, [
                h(
                    'Button',
                    {
                      class: `${
                          params.row.status && ['approved'].includes(params.row.status)
                              ? 'pt-btn-1'
                              : 'btn-hide'
                      }`,
                    },
                    [
                      h('Icon', {
                        props: {
                          type: 'ios-remove-circle-outline',
                          size: '25',
                        },
                        on: {
                          click: () => {
                            this.handleProcessRequest(params.row._id, 'cancel');
                          },
                        },
                      }),
                    ],
                    'reject'
                ),
              ])
            ]);
          },
        },
      ],
      tblData: [],
    };
  },

  watch: {
    requestsDataList(data) {
      this.tblData = data;
    },
    totalPerPage(total) {
      const arrTotal = [];
      arrTotal.push(total);
    },
  },
  created() {
    if (this.requestsDataList) {
      this.tblData = this.requestsDataList;
    }
  },
  methods: {
    handleReloadList() {
      this.$emit('reload');
    },
    handleOnpageChanged(page) {
      this.$emit('on-page-change', page);
    },
    handleProcessRequest(id, status) {
      console.log('handleProcessRequest status: ', status)
      this.$emit('on-process-request', id, status);
    },
  },
};
</script>

<style lang="scss">
ul.pagination.ivu-page {
  text-align: end;
}
.adminRequestsList button {
  width: 25px;
  margin-left: 10px;
}
.adminRequestsList i.ivu-icon {
  margin-left: -12.5px;
}
.btn-hide {
  display: none;
}
.statusLabel {
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  vertical-align: middle;
  max-width: 250px;
  padding: 5px;
  text-align: center;
}
.success {
  background: #49e97e;
  font-weight: bold;
}
.warning {
  background: #ffc107;
  font-weight: bold;
}
.danger {
  background: #e9495d;
  font-weight: bold;
}
.primary {
  background: #211841;
}
.secondary {
  background: #828899;
}
</style>
